import React, { useState } from 'react';
import { object } from 'prop-types';
import { RichText } from 'prismic-reactjs';
import Image from '@components/Image/Image';
import styles from './Features.module.scss';

const Features = ({ primary }) => {
  const { title, description, image, list_title, list } = primary;
  const [division, setDivision] = useState(5);
  console.log(division, 'list');

  if (list.richText.length) {
    if (list.richText.length % 2 === 0) {
      setDivision(list.richText.length / 2);
    }
    if (list.richText.length % 2 !== 0) {
      setDivision(list.richText.length / 2 - 1);
    }
  }

  return (
    <section className={styles.section}>
      <div className={styles.title}>
        <RichText render={title.richText} />
      </div>
      <div className={styles.description}>
        <RichText render={description.richText} />
      </div>
      <div className={styles.imageWrapper}>
        <Image image={image} />
      </div>
      <div className={styles.container}>
        <div className={styles.listTitle}>
          <RichText render={list_title.richText} />
        </div>
        <div className={styles.listWrapper}>
          <div className={styles.columnOne}>
            {list.richText.map((item, index) => {
              return <>{index < division && <p>{item.text}</p>}</>;
            })}
          </div>
          <div className={styles.columnTwo}>
            {list.richText.map((item, index) => {
              return <>{index >= division && <p>{item.text}</p>}</>;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

Features.propTypes = {
  primary: object,
};

export default Features;
